export const fields = [
    { key: 'index', label: "No", _style:'min-width:30px'},
    { key: 'select', label: "", _style:'min-width:30px' },
    { key: 'fid', label: "FID", _style:'min-width:30px' },
    { key: 'object_id', label: "OBJECTID", _style:'min-width:30px' },
    { key: 'kecamatan', label: "Kecamatan", _style:'min-width:100px;' },
    { key: 'desa', label: "Desa", _style:'min-width:100px;' },
    { key: 'jns_lhn', label: "Jenis Lahan", _style:'min-width:100px;' },
    { key: 'survey', label: "Survey", _style:'min-width:100px;' },
    { key: 'created_at', label: "Tanggal Dibuat", _style:'min-width:100px;' },
    { key: 'updated_at', label: "Tanggal Diubah", _style:'min-width:100px;' },
    { key: "action", label: 'Aksi', _style: 'min-width:75px'}
]
